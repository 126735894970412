<template>

<app-wrapper class="form">

	<app-text v-if="is.success" :text="$state.page.success_complete" />

	<template v-if="!is.success">
		
		<app-textbox v-model="model.firstname" placeholder="First Name" />
		<app-textbox v-model="model.lastname" placeholder="Family Name" />
		<app-textbox v-model="model.business" placeholder="Business name (if applicable)" />
		<app-textbox v-model="model.email" placeholder="E-mail" />
		<app-textbox v-model="model.country" placeholder="Country" />
		<app-textbox v-model="model.postcode" placeholder="Postcode" />
		<app-textbox v-model="model.phone" placeholder="Telephone" />

		<div class="form-indent">

			<p class="form-note">How would you like to be contacted?</p>

			<div class="form-toggles">

				<app-checkbox v-model="model.by_phone" text="Phone" />
				<app-checkbox v-model="model.by_email" text="Email" />

			</div>

		</div>

		<template v-if="!isSimple">

			<app-textbox v-model="model.message" placeholder="How can we help you? Please provide a brief description of your legal issue" :area="true" />

			<div class="form-indent">

				<p class="form-note">Which consultation do you wish to book?</p>

				<div class="form-toggles">

					<app-checkbox v-model="model.with_partner" text="Partner £300 (£360 inc VAT)" />
					<app-checkbox v-model="model.with_associate" text="Associate £200 (£240 inc VAT)" />

				</div>

			</div>

			<app-selectbox v-model="model.lawyer" placeholder="Do you wish to speak with a particular lawyer?" :options="$state.page.lawyers" />
			<app-textbox v-model="model.refer" placeholder="How did you hear of us?" />

		</template>

		<div class="form-indent">

			<p v-if="$state.page.form_note" class="form-note">{{ $state.page.form_note }}</p>

			<app-checkbox v-model="model.policy" :text="$state.page.form_policy" />
			<app-checkbox v-model="model.consent" :text="$state.page.form_consent" />

		</div>
		
		<vue-recaptcha ref="captcha" v-on:verify="onVerified" sitekey="6LeEVkUlAAAAAMt8kSWv_B3nRSqarVttgEtsxKWZ" class="form-captcha" />

		<p class="form-message is-error" v-if="is.errored">{{ $state.page.error_invalid }}</p>

		<button class="form-submit" :class="{'is-disabled': is.submitting || $v.$invalid, 'is-errored': is.errored}" v-on:click="onSubmitClick">
			<template v-if="!is.submitting && !is.errored">{{ $state.page.form_button }}</template>
			<template v-if="!is.submitting && is.errored">Please try again</template>
			<i class="fa fa-spinner fa-spin" v-if="is.submitting"></i>
		</button>

	</template>

</app-wrapper>

</template>

<script>

import { VueRecaptcha } from 'vue-recaptcha'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

export default {
	
	mixins: [validationMixin],

	props: ['isSimple'],

	components: {
		VueRecaptcha
	},

	data: function() {

		return {
			is: {
				submitting: false,
				errored: false,
				success: false
			},
			model: {
				with_partner: 0,
				with_associate: 0,
				by_phone: 0,
				by_email: 0,
				email: '',
				lastname: '',
				firstname: '',
				business: '',
				country: '',
				phone: '',
				postcode: '',
				message: '',
				captcha: '',
				policy: 0,
				consent: 0,
				lawyer: 0,
				refer: ''
			}
		}

	},

	validations: {
		model: {
			firstname: {
				required
			},
			lastname: {
				required
			},
			message: {
				
			},
			email: {
				required,
				email
			},
			phone: {
				required
			},
			captcha: {
				required
			},
			policy: {
				required
			},
			consent: {
				required
			}
		}
	},

	watch: {

		model: {

			handler: function() {

				this.is.errored = false
				this.$v.$touch()

			},

			deep: true

		} 

	},

	methods: {

		onVerified: function(e) {

			this.model.captcha = e

		},

		onSubmitClick: function() {

			if (this.is.submitting || this.$v.$invalid) return false

			this.is.submitting = true
			this.is.errored = false

			this.$api.post('request', this.model).then(function() {

				this.is.submitting = false
				this.is.success = true

				window.scrollTo(0, 0)
				
			}.bind(this), function() {

				this.$refs.captcha.reset()

				this.is.submitting = false
				this.is.errored = true

			}.bind(this))

		}
			
	}

}

</script>

<style scoped>

.form {
	margin-top: 64px;
}

.is-tablet .form {
	margin-top: 48px;
}

.is-tablet .form {
	margin-top: 32px;
}

.form-indent {
	padding: 20px;
}

.is-mobile .form-indent {
	padding: 0px 0px 10px 0px;
}

.form-toggles {
	display: flex;
}

.is-mobile .form-toggles {
	flex-direction: column;
}

.form-toggles >>> .checkbox {
	width: 200px;
	margin-right: 20px;
}

.is-mobile .form-toggles >>> .checkbox {
	width: 100%;
	margin-right: 0px;
}

.form p.form-note {
	font-size: 16px;
	line-height: 26px;
	color: #1A2046;
	margin-bottom: 20px;
}

.is-device .form p.form-note {
	font-size: 14px;
}

.form p.form-message {
	text-align: center;
	font-size: 16px;
	line-height: 20px;
	color: #fff;
	padding: 20px;
	border-radius: 5px;
}

.form p.form-message.is-success {
	background-color: #BBD9CD;
}

.form p.form-message.is-error {
	background-color: #cc6e73;
}

.form p.form-note a {
	font-weight: 500;
}

.form-captcha {
	margin: 40px auto;
	width: 300px;
}

.is-mobile .form-captcha {
	margin: 32px auto;
	margin-left: -12px;
	width: 280px;
}

.form-submit {
	height: 56px;
	border-radius: 36px;
	font-size: 16px;
	font-weight: 500;
	color: #fff;
	width: 200px;
	text-align: center;
	user-select: none;
	line-height: 56px;
	background-color: #485CC7;
	cursor: pointer;
	margin: 0px auto;
    display: block;
}

.form-submit.is-disabled {
	pointer-events: none;
	opacity: 0.75;
}

.form-submit.is-errored {
	color: #fff;
	background-color: #cc6e73;
}

.is-device .form-submit {
	height: 40px;
	line-height: 40px;
	font-size: 14px;
}


.is-desktop .form-submit:hover {
	background-color: #112a45;
	color: #fff;
}

</style>
